<template>
  <div class="shiyanshi">
    <div ref="ceiling" :class="{ fixed: isFixed }">
      <Head></Head>
    </div>
    <div class="weiyou-title">
      <img src="@/image/shiyanshi/shiyanshi-1.png" alt="" />
      <!--      <img src="@/image/shiyanshi/shiyanshi-2.png" alt="" />-->
      <div class="prize-title-bg3">
        <div class="prize-video">
          <video-player
            :video-url="videoPath"
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="false"
            :options="playerPrizeOptions"
          >
          </video-player>
        </div>
      </div>
      <img src="@/image/shiyanshi/shiyanshi-2.jpg" alt="" />
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
export default {
  name: "shiiyanshi",
  components: { Head, Foot },
  data() {
    return {
      videoPath: "",
      isFixed: true,
      playerPrizeOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/%E7%AE%97%E5%8A%9B.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        poster: require("@/image/shiyanshi/shiyanshi-5.png"), //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const ceilingEle = this.$refs.ceiling;
      if (scrollTop > ceilingEle.offsetTop) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;

  top: 0;

  left: 0;

  right: 0;

  z-index: 99;
}
.shiyanshi {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  img {
    width: 75%;
    height: 100%;
  }
}
.prize-title-bg3 {
  padding: 5vh 19vw;
  margin-top: 10vh;
}
</style>
